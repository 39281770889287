<template>
  <div id="previewWrapper">
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" id="pointer">
      <path d="M100 0 L0 50 L100 100" />
    </svg>
    <section id="preview">
      <template v-if="document != null">
        <div id="hnEntry">
          <div class="row">
            <div>
              <svg
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                id="backBtn"
                @click="$emit('back')"
              >
                <path d="M100 0 L0 50 L100 100" />
              </svg>
            </div>
            <div>
              <h1 class="title">
                <a
                  :href="
                    document.url ||
                      `https://news.ycombinator.com/item?id=${document.id}`
                  "
                  >{{ document.title || document.titleWeb }}</a
                >
              </h1>

              <div class="meta">
                <a
                  :href="
                    document.url ||
                      `https://news.ycombinator.com/item?id=${document.id}`
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ document.domain || "news.ycombinator.com" }}</a
                >

                <span class="ml">
                  {{
                    new Date(document.time * 1000).toLocaleDateString(
                      undefined,
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric"
                      }
                    )
                  }}
                </span>

                <span class="ml"
                  >by:
                  <a
                    href="http://google.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ document.by }}</a
                  ></span
                >

                <span class="ml">
                  comments <span class="num">{{ document.descendants }}</span>
                </span>

                <span class="ml">
                  points <span class="num">{{ document.score }}</span>
                </span>

                <a
                  v-if="document.url"
                  class="ml"
                  :href="`https://web.archive.org/web/*/${document.url}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  >archive</a
                >
              </div>

              <a
                class="hnLink"
                v-if="document.url"
                :href="`https://news.ycombinator.com/item?id=${document.id}`"
                >{{
                  `https://news.ycombinator.com/item?id=${document.id} (on HN)`
                }}</a
              >
            </div>
          </div>

          <div class="divider"></div>

          <div class="hashtags">
            <span
              v-for="tag in document.hashtags"
              :key="tag"
              @click="$emit('hashtag', tag)"
              >{{ tag }}</span
            >
          </div>

          <p
            class="htmlPreview"
            v-html="
              highlight(document.cleanHtml || document.text, query, false)
            "
          ></p>
        </div>
      </template>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { HNEntry } from "../utils/HNEntry";
import { removeHTTP, highlight } from "../utils/utils";

export default defineComponent({
  data() {
    return { removeHTTP, highlight };
  },
  props: {
    document: {
      type: Object as () => HNEntry
    },
    query: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss">
@import "@/styles/preview.scss";

#previewWrapper {
  width: 37vw;
  position: relative;

  @media (max-width: 750px) {
    display: none;
    width: 100vw;

    &.mobileShow {
      display: unset;
    }
  }

  #pointer {
    position: absolute;
    width: 10px;
    height: 40px;
    z-index: 5;
    margin-top: -30px;
    margin-left: 21px;

    @media (max-width: 750px) {
      display: none !important;
    }

    path {
      fill: white;
      stroke-width: 5px;
      stroke: $lightgrey;
    }
  }
}

#preview {
  margin: 30px 0 0 30px;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparentize($color: $lightgrey, $amount: 0.4);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparentize($color: $lightgrey, $amount: 0);
  }

  padding: 0 20px;
  border-left: 1px solid lighten($color: $lightgrey, $amount: 15);
  position: fixed;
  box-sizing: border-box;
  height: calc(100% - 30px);
  width: inherit;
  top: 0;

  @media (max-width: 750px) {
    border: none;
    padding: 30px 20px;
    margin: 0;
    left: 0;
  }

  .highlight {
    background-color: transparentize($color: $lightgrey, $amount: 0.7);
  }

  #hnEntry {
    margin: 0 0 15px;

    .row {
      display: flex;
      align-items: center;
    }

    #backBtn {
      width: 10px;
      height: 40px;
      cursor: pointer;

      margin-right: 8px;
      padding: 10px;

      path {
        fill: white;
        stroke-width: 5px;
        stroke: $lightgrey;
      }

      @media (min-width: 750px) {
        display: none;
      }
    }

    .title {
      font-weight: normal;
      font-size: 13pt;
      margin: 0;
    }

    .hnLink {
      font-size: 11pt;
      opacity: 0.7;
      display: block;
      margin-top: 5px;
      word-break: break-all;
    }

    .meta {
      margin: 0;
      margin-top: 3px;
      font-size: 10pt;
      // opacity: 0.7;

      display: flex;
      flex-wrap: wrap;

      @media (max-width: 750px) {
        flex-direction: column;
      }

      .ml {
        margin-left: 10px;
        @media (max-width: 750px) {
          margin-left: 0px;
        }
      }

      .num {
        padding: 1px 6px;
        border-radius: 4px;
        background-color: transparentize($color: $lightgrey, $amount: 0.7);
      }
    }

    .divider {
      margin: 10px 0;
      background-color: lighten($color: $lightgrey, $amount: 15);
      height: 1px;
      width: 100%;
    }

    .hashtags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      span {
        cursor: pointer;
        background-color: transparentize($color: $primary, $amount: 0.9);
        font-size: 8.5pt;
        font-weight: 500;
        color: $primary;
        padding: 2px 8px;
        border-radius: 5px;

        margin-top: 5px;
        margin-right: 7px;

        &:hover {
          background-color: transparentize($color: $primary, $amount: 0.82);
        }
      }
    }

    .htmlPreview {
      font-size: 11pt;
    }
  }
}
</style>
