<template>
  <div class="facetFilter" :class="range ? 'range' : ''">
    <h4>{{ name }}</h4>
    <input
      type="text"
      :value="query"
      v-if="query !== undefined"
      :placeholder="`Search ${name.toLocaleLowerCase()}...`"
      @input="$emit('update:query', $event.target.value)"
    />
    <div
      v-if="range && modelValue"
      class="facetEntry"
      style="margin-bottom: 3px"
      @click="$emit('update:modelValue', null)"
    >
      &lt; All posts
    </div>
    <div
      class="facetEntry"
      v-for="val in values.filter(v => !range || v.count)"
      :style="{
        fontWeight: !isActive(val.value) ? undefined : 500,
        color: !isActive(val.value) ? undefined : 'black'
      }"
      :key="`${val.value}`"
      @click="
        $emit(
          'update:modelValue',
          !isActive(val.value)
            ? range
              ? val.value
              : [val.value, ...(modelValue || [])]
            : range
            ? null
            : modelValue.filter(v => v != val.value)
        )
      "
    >
      <input type="checkbox" v-if="!range" :checked="isActive(val.value)" />
      {{ range ? `>=${val.value.from}` : val.value }}
      <span v-if="val.count !== undefined">{{
        range ? val.count : `(${val.count})`
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {};
  },

  emits: ["update:modelValue", "update:query"],

  props: {
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object
    },
    query: {
      type: String
    },
    values: {
      type: Array as () => {
        value: string | { from: number; to: number };
        count: number;
      }[],
      required: true
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    isActive(val: string | { from: number; to: number }) {
      if (this.range && typeof val !== "string")
        return this.modelValue?.from == val.from;
      else return !!this.modelValue && this.modelValue.indexOf(val) !== -1;
    }
  }
});
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 2px;
  color: $darkgrey;
  font-weight: 500;
}

input {
  margin-bottom: 5px;
  border: 1px solid $lightgrey;
  color: $darkgrey;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $lightgrey;
  }
}

.facetEntry {
  cursor: pointer;
  font-size: 11pt;

  span {
    color: $grey;
  }
}

.range .facetEntry span {
  // display: inline-block;
  background-color: transparentize($color: $lightgrey, $amount: 0.7);
  border-radius: 4px;
  padding: 0px 5px;
  font-size: 10pt;
}
</style>
