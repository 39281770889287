<template>
  <div
    class="searchbar-large"
    @mouseleave="showSuggestions = false"
    @mouseenter="showSuggestions = true"
  >
    <input
      type="text"
      ref="input"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('update:modelValue', $event.target.value)"
      @keydown="handleKey"
      spellcheck="false"
    />
    <div class="suggestions" v-if="showSuggestions">
      <div
        class="suggestion"
        :class="i == suggestionSelection ? 'active' : ''"
        v-for="(sugg, i) in suggestions"
        :key="sugg"
      >
        <div @mousedown.prevent @click="selectSuggestion(sugg)">
          <span>{{
            sugg.toLowerCase().startsWith(modelValue.toLowerCase())
              ? modelValue
              : ""
          }}</span
          >{{
            sugg.toLowerCase().startsWith(modelValue.toLowerCase())
              ? sugg.substring(modelValue.length)
              : sugg
          }}
        </div>
      </div>
    </div>
    <div class="searchBtn">
      <svg viewBox="0 0 20 50" preserveAspectRatio="none">
        <polygon points="0,0 20,0 20,50 18,50" />
        Sorry, your browser does not support inline SVG.
      </svg>
      <img src="@/assets/searchIcon.svg" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      showSuggestions: false,
      suggestionSelection: -1,
      lastActionDel: false
    };
  },

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: String,
      required: true
    },
    correction: {
      type: String,
      required: true
    },
    suggestions: {
      type: Array as () => string[],
      default: []
    }
  },

  watch: {
    correction(val) {
      if (this.isMobile) return;

      if (
        val.toLowerCase().startsWith(this.modelValue.toLowerCase()) &&
        !this.lastActionDel
      ) {
        const input = this.modelValue + val.substring(this.modelValue.length);
        // this.$emit("update:modelValue", input);
        (this.$refs.input as HTMLInputElement).value = input;
        (this.$refs.input as HTMLInputElement).setSelectionRange(
          this.modelValue.length,
          val.length
        );
      }
    },

    modelValue() {
      if (this.isMobile) return;

      this.checkModelValue();
    }
  },

  computed: {
    suggestionsClean(): string[] {
      return this.suggestions.filter(sugg => sugg != this.modelValue);
    },

    isMobile(): boolean {
      return window.innerWidth <= 750;
    }
  },

  mounted() {
    this.checkModelValue();
  },

  methods: {
    selectSuggestion(sugg: string) {
      (this.$refs.input as HTMLInputElement).value = sugg;
      (this.$refs.input as HTMLInputElement).blur();
      this.$emit("update:modelValue", sugg);
    },

    checkModelValue() {
      this.suggestionSelection = -1;

      if ((this.$refs.input as HTMLInputElement).value != this.modelValue) {
        (this.$refs.input as HTMLInputElement).value = this.modelValue;
        // alert("T1");
      }

      if (
        this.correction
          .toLowerCase()
          .startsWith(this.modelValue.toLowerCase()) &&
        !this.lastActionDel
      ) {
        const input =
          this.modelValue + this.correction.substring(this.modelValue.length);
        (this.$refs.input as HTMLInputElement).value = input;
        (this.$refs.input as HTMLInputElement).setSelectionRange(
          this.modelValue.length,
          this.correction.length
        );
        // alert("T2");
      }
    },

    handleKey(event: KeyboardEvent) {
      if (event.key == "Backspace") {
        this.lastActionDel = true;
      } else {
        this.lastActionDel = false;
      }

      if (event.key == "Escape") {
        (this.$refs.input as HTMLInputElement).blur();
      }

      if (event.key == "Enter") {
        if (
          this.suggestionSelection != -1 &&
          this.suggestions[this.suggestionSelection]?.toLowerCase() !=
            (this.$refs.input as HTMLInputElement).value.toLowerCase()
        ) {
          const sugg = this.suggestions[
            this.suggestionSelection
          ]?.toLowerCase();
          const input = sugg.startsWith(this.modelValue.toLowerCase())
            ? this.modelValue + sugg.substring(this.modelValue.length)
            : sugg;
          this.$emit("update:modelValue", input);
        } else {
          (this.$refs.input as HTMLInputElement).blur();
        }
      }

      if (event.key == "ArrowDown") {
        if (this.suggestionsClean.length > this.suggestionSelection + 1) {
          this.suggestionSelection += 1;
        } else {
          this.suggestionSelection = -1;
        }
      } else if (event.key == "ArrowUp") {
        if (this.suggestionSelection - 1 >= -1) {
          this.suggestionSelection -= 1;
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.searchbar-large {
  display: flex;
  position: relative;

  input {
    width: inherit;
    border: 1px solid $grey;
    color: $primary;
    padding: 5px 15px 5px 10px;
    font-size: 14pt;
    flex: 1;

    &:focus {
      outline: none;
    }

    &:focus + .suggestions {
      display: block;
    }
  }

  .suggestions {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 3;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    .suggestion {
      padding: 4px 10px;
      // border-top: 1px solid $light;

      &.active {
        background-color: transparentize($color: $lightgrey, $amount: 0.7);
      }

      &:hover {
        background-color: transparentize($color: $lightgrey, $amount: 0.7);
      }

      span {
        color: $primary;
        font-weight: 500;
      }

      &:nth-child(1) {
        border-top: none;
      }
    }
  }

  .searchBtn {
    cursor: pointer;
    background-color: $primary;
    padding: 8px 20px 7px 10px;
    position: relative;

    svg {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-97%);

      polygon {
        fill: $primary;
      }
    }

    &:hover {
      background-color: darken($color: $primary, $amount: 5);

      svg polygon {
        fill: darken($color: $primary, $amount: 5);
      }
    }
  }
}
</style>
