
import { defineComponent } from "vue";
import FacetFilter from "./FacetFilter.vue";

export default defineComponent({
  components: { FacetFilter },
  data() {
    return {
      facetNames: {
        hashtags: "Hashtags",
        score: "Points",
        descendants: "Comments",
        domain: "Domain"
      }
    };
  },

  emits: ["update:facets", "update:searchIn", "update:facetFilterQuery"],

  props: {
    hasQuery: {
      type: Boolean,
      required: true
    },
    availableFacets: {
      type: Array as () => { field: string; type: string; values: [] }[],
      required: true
    },
    facets: {
      type: Object,
      required: true
    },
    searchIn: {
      type: Array as () => string[],
      required: true
    },
    facetFilterQuery: {
      type: Object as () => { [key: string]: string },
      required: true
    }
  },

  methods: {
    closeFilter() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById("sidebarWrapper")!.style.display = "";
    }
  }
});
