<template>
  <div class="range" ref="range">
    <div
      class="handle1"
      @mousedown="startDragLower"
      @touchstart="startDragLower"
      :style="{ marginLeft: `${lowerRel * 100}%` }"
    ></div>
    <div
      class="handle2"
      @mousedown="startDragHigher"
      @touchstart="startDragHigher"
      :style="{ marginLeft: `${higherRel * 100}%` }"
    ></div>
    <div
      class="beam"
      :style="{
        marginLeft: `${lowerRel * 100}%`,
        width: `${(higherRel - lowerRel) * 100}%`
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      lowerEdit: false,
      updater: null as null | ((val: number) => void)
    };
  },

  mounted() {
    document.addEventListener("mouseup", this.endDrag, { capture: true });
    document.addEventListener("touchend", this.endDrag, { capture: true });
    document.addEventListener("mousemove", this.updateMouse, { capture: true });
    document.addEventListener("touchmove", this.updateTouch, { capture: true });
  },

  unmounted() {
    document.removeEventListener("mouseup", this.endDrag, { capture: true });
    document.removeEventListener("touchend", this.endDrag, { capture: true });
    document.removeEventListener("mousemove", this.updateMouse, {
      capture: true
    });
    document.removeEventListener("touchmove", this.updateTouch, {
      capture: true
    });
  },

  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    lower: {
      type: Number,
      required: true
    },
    higher: {
      type: Number,
      required: true
    },
    round: {
      type: Boolean,
      default: true
    }
  },

  emits: ["update:lower", "update:higher"],

  computed: {
    lowerRel(): number {
      return (this.lower - this.min) / (this.max - this.min);
    },
    higherRel(): number {
      return (this.higher - this.min) / (this.max - this.min);
    }
  },

  methods: {
    startDragLower() {
      this.lowerEdit = true;
      this.updater = val => this.$emit("update:lower", val);
    },

    startDragHigher() {
      this.lowerEdit = false;
      this.updater = val => this.$emit("update:higher", val);
    },

    updateMouse(event: MouseEvent) {
      this.updateHandle(event.clientX);
    },

    updateTouch(event: TouchEvent) {
      const touch = event.touches.item(0);
      if (touch != null && this.updater != null) {
        event.preventDefault();
        this.updateHandle(touch.clientX);
      }
    },

    updateHandle(xPos: number) {
      if (this.updater != null) {
        const box = (this.$refs.range as HTMLElement).getBoundingClientRect();
        let val =
          ((xPos - box.x) / box.width) * (this.max - this.min) + this.min;

        if (
          val < this.min ||
          val > this.max ||
          (this.lowerEdit && val > this.higher) ||
          (!this.lowerEdit && val < this.lower)
        )
          return;

        if (this.round) val = Math.round(val);

        this.updater(val);
      }
    },

    endDrag() {
      this.updater = null;
    }
  }
});
</script>

<style lang="scss" scoped>
.range {
  margin-left: 7.5px;
  margin-right: 7.5px;
  height: 3px;
  background-color: $light;
  position: relative;
  user-select: none;

  .beam {
    height: 3px;
    background-color: $primary;
  }

  .handle1,
  .handle2 {
    transform: translateX(-7.5px);

    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    margin-top: -6px;
    border-radius: 10px;

    background-color: $primary;
  }
}
</style>
