function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function highlight(text: string, query: string, escapeHTML = true) {
  if (!text) return text;

  const keywords = query
    .replace(new RegExp('"', "gi"), "")
    .split(" ")
    .filter(v => !!v)
    .map(escapeRegExp);

  if (escapeHTML) {
    try {
      text = escapeHtml(text).replace(
        new RegExp(`(?<!&[ampltgquo039#]*)${keywords.join("|")}`, "gi"),
        "<b class='highlight'>$&</b>"
      );
    } catch {
      text = escapeHtml(text).replace(
        new RegExp(keywords.join("|"), "gi"),
        "<b class='highlight'>$&</b>"
      );
    }
  } else {
    for (const keyword of keywords) {
      text = text.replace(
        new RegExp(`${keyword}(?=[a-zA-Z-]*( |<|$))`, "gi"),
        "<b class='highlight'>$&</b>"
      );
    }
  }

  return text;
}

export function debounce(func: Function, wait: number) {
  let timeout: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(this: any) {
    if (timeout != null) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this);
    }, wait);
  };
}

export function removeHTTP(url: string): string {
  if (!url) return url;

  const ind = url.indexOf("://");
  if (ind == -1) return url;

  return url.substr(ind + 3);
}
