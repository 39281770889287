
import { defineComponent } from "vue";
import { HNEntry } from "../utils/HNEntry";
import { removeHTTP, highlight } from "../utils/utils";

export default defineComponent({
  data() {
    return {
      removeHTTP,
      highlight
    };
  },

  props: {
    document: {
      type: Object as () => HNEntry,
      required: true
    },
    query: {
      type: String,
      required: true
    }
  },
  emits: ["url-filter"]
});
