<template>
  <article class="result">
    <div class="titleSpec">
      {{ document.descendants }}
    </div>
    <div class="titleSpec">
      {{ document.score }}
    </div>
    <div>
      <h1>
        <a
          :href="
            document.url ||
              `https://news.ycombinator.com/item?id=${document.id}`
          "
          v-html="highlight(document.title || document.titleWeb, query)"
        ></a>
        <span
          v-if="document.domain"
          @click="$emit('url-filter', document.domain)"
          style="cursor: pointer"
        >
          ({{ document.domain }})</span
        >
        <span v-else>(news.ycombinator.com)</span>
      </h1>
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { HNEntry } from "../utils/HNEntry";
import { removeHTTP, highlight } from "../utils/utils";

export default defineComponent({
  data() {
    return {
      removeHTTP,
      highlight
    };
  },

  props: {
    document: {
      type: Object as () => HNEntry,
      required: true
    },
    query: {
      type: String,
      required: true
    }
  },
  emits: ["url-filter"]
});
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  // margin: 5px 0;
  padding: 10px 0;
  align-items: center;

  &.active {
    background-color: transparentize($color: $lightgrey, $amount: 0.7);
  }

  .titleSpec {
    min-width: 40px;
    font-size: 11pt;
    font-weight: 500;
    padding-right: 15px;
    text-align: right;
  }

  h1 {
    margin: 0;
    font-size: 13pt;
    font-weight: normal;
    word-break: break-word;

    a {
      margin-right: 10px;
      word-break: break-word;
    }

    span {
      font-size: 10pt;
      color: $lightgrey;
    }
  }

  p {
    margin: 0;
    font-size: 11pt;
    max-height: 57px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // overflow: hidden;
  }
}
</style>
