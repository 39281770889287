
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {};
  },

  emits: ["update:modelValue", "update:query"],

  props: {
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object
    },
    query: {
      type: String
    },
    values: {
      type: Array as () => {
        value: string | { from: number; to: number };
        count: number;
      }[],
      required: true
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    isActive(val: string | { from: number; to: number }) {
      if (this.range && typeof val !== "string")
        return this.modelValue?.from == val.from;
      else return !!this.modelValue && this.modelValue.indexOf(val) !== -1;
    }
  }
});
