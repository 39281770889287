<template>
  <span class="legend"
    >number of HN posts {{ hoverText !== null ? `- ${hoverText}` : "" }}</span
  >
  <div class="bars">
    <div
      class="bar"
      v-for="i in [...Array(thisYear - 2007 + 1).keys()]"
      :key="i"
      @mouseenter="hoverText = `${values[i]} (${i + 2007})`"
      @mouseleave="hoverText = null"
    >
      <div
        :style="{
          height: `${values[i] ? (100 / max) * values[i] : 0}%`,
          opacity:
            i + 2007 >= rangeFilter.from && i + 2007 <= rangeFilter.to
              ? '1'
              : '0.5'
        }"
      ></div>
    </div>
  </div>
  <range-slider
    :min="2007"
    :max="thisYear"
    :lower="rangeFilter.from"
    :higher="rangeFilter.to"
    @update:lower="
      $emit('update:rangeFilter', { from: $event, to: rangeFilter.to })
    "
    @update:higher="
      $emit('update:rangeFilter', { from: rangeFilter.from, to: $event })
    "
  ></range-slider>
  <div class="histogramInfo">
    <span>2007</span>
    <span
      ><span>{{ rangeFilter.from }} </span> date filter
      <span> {{ rangeFilter.to }}</span></span
    >
    <span>{{ thisYear }}</span>
  </div>
</template>

<script lang="ts">
import RangeSlider from "./RangeSlider.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { RangeSlider },
  data() {
    return {
      thisYear: new Date().getUTCFullYear(),
      hoverText: null as null | string
    };
  },

  props: {
    values: {
      type: Array as () => number[],
      required: true
    },
    rangeFilter: {
      type: Object as () => { from: number; to: number },
      required: true
    }
  },

  emits: ["update:rangeFilter"],

  computed: {
    max(): number {
      return this.values.reduce((v1, v2) => Math.max(v1, v2), 1);
    }
  }
});
</script>

<style lang="scss" scoped>
.histogramInfo {
  margin-top: 7px;
  font-size: 11pt;
  display: flex;
  justify-content: space-between;

  span:nth-child(2) {
    color: $primary;
    font-size: 10pt;

    span {
      color: $lightgrey;
      margin: 0 10px;
    }
  }
}

.legend {
  font-size: 10pt;
}

.bars {
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 5px -1px 15px;
  .bar {
    // max-width: 40px;
    height: 100%;
    margin: 0 2px;
    flex: 1;

    &:hover div {
      background-color: darken($color: $primary, $amount: 5);
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    div {
      background-color: $primary;
      min-height: 1px;
    }
  }
}
</style>
