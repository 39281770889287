<template>
  <sidebar
    @mouseenter="selectedDocument = null"
    :availableFacets="availableFacets"
    :hasQuery="!!query"
    v-model:searchIn="searchIn"
    v-model:facets="selectedFacets"
    v-model:facetFilterQuery="facetFilterQuery"
  ></sidebar>
  <main id="mainSearch" :class="selectedMobileDocument ? 'mobileHide' : ''">
    <div @mouseenter="selectedDocument = null">
      <div class="mobileTitle">
        <a href="/" style="color: unset">
          <h1>DeepHN</h1>
          <!-- <p>
            Full-text search 26 million HN posts <br />
            AND 3 million linked web pages
          </p> -->
        </a>

        <span>Powered by </span>
        <a
          href="https://seekstorm.com"
          target="_blank"
          rel="noopener noreferrer"
          >SeekStorm</a
        >
      </div>

      <searchbar
        id="searchbar"
        v-model="query"
        :suggestions="suggestions"
        :correction="actualQuery"
      ></searchbar>
      <div id="searchbar-details">
        <div>{{ count.toLocaleString() }} results</div>
        <div
          v-if="
            (loading && lastTriggerQuery) ||
              actualQuery.toLowerCase() == query.toLocaleLowerCase() ||
              forceExactQuery
          "
        >
          try:
          <span
            v-for="(sug, i) in ['bitcoin', 'apple silicon', 'dogecoin']"
            :key="sug"
          >
            {{ i != 0 ? ", " : ""
            }}<a href="#" @click.prevent="query = sug">{{ sug }}</a>
          </span>
        </div>
        <div v-else>
          searching for
          <a href="#" @click.prevent="query = actualQuery">{{ actualQuery }}</a
          >. search for
          <a
            href="#"
            @click.prevent="
              forceExactQuery = true;
              queryDocuments();
            "
            >{{ query }}</a
          >
          instead
        </div>
      </div>
      <histogram
        :values="
          availableFacets
            .find(f => f.field == 'time')
            ?.values.map(val => val.count) || []
        "
        v-model:rangeFilter="rangeFilter"
      ></histogram>
    </div>

    <div id="filterBtn" @click="showFilters()">
      <img src="@/assets/filterIcon.svg" /> Select filters
    </div>

    <span id="sortByMobile">sort by:</span>
    <div class="resultLegend">
      <div
        @click="sortBy = 'descendants'"
        :class="sortBy == 'descendants' ? 'active' : ''"
      >
        <span>comments</span>
      </div>
      <div @click="sortBy = 'score'" :class="sortBy == 'score' ? 'active' : ''">
        <span>points</span>
      </div>
      <div
        v-if="!!query"
        @click="sortBy = ''"
        :class="sortBy == '' ? 'active' : ''"
      >
        <span>rank</span>
      </div>
      <div
        @click="sortBy = 'newest'"
        :class="sortBy == 'newest' ? 'active' : ''"
      >
        <span>newest</span>
      </div>
      <div
        @click="sortBy = 'oldest'"
        :class="sortBy == 'oldest' ? 'active' : ''"
      >
        <span>oldest</span>
      </div>
      <span id="sortBy">- sort by</span>
    </div>
    <div>
      <result
        v-for="(doc, i) in documents"
        :key="i"
        :document="doc"
        :class="selectedDocument == doc ? 'active' : ''"
        :query="actualQuery"
        @url-filter="addUrlFilter"
        @click="selectDoc($event, doc)"
        @mouseenter="
          selectedDocument = doc;
          selectedDocumentElement = $event.target;
        "
      ></result>
    </div>
    <div class="loadingBlock" v-if="loading && documents.length"></div>
  </main>
  <preview
    :class="selectedMobileDocument ? 'mobileShow' : ''"
    :document="selectedDocument || selectedMobileDocument"
    :query="actualQuery"
    @back="selectedMobileDocument = null"
    @hashtag="addHashtag"
  ></preview>
</template>

<script lang="ts">
import Preview from "@/components/Preview.vue";
import Result from "@/components/Result.vue";
import Searchbar from "@/components/Searchbar.vue";
import { defineComponent } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import Histogram from "@/components/Histogram.vue";

import { debounce } from "@/utils/utils";
import { HNEntry } from "@/utils/HNEntry";

const QUERY_LENGTH = 20;

export default defineComponent({
  name: "App",
  components: { Sidebar, Searchbar, Histogram, Result, Preview },

  data() {
    return {
      query: "",
      actualQuery: "",
      sortBy: "newest",
      searchIn: ["Stories"] as string[],
      facetFilterQuery: {
        domain: "",
        hashtags: ""
      },
      count: "-" as number | string,
      inStartup: true,
      suggestions: [] as string[],
      selectedMobileDocument: null as null | HNEntry,
      selectedDocument: null as null | HNEntry,
      selectedDocumentElement: null as null | HTMLElement,
      queryDebounce: debounce(this.queryDocuments, 150),
      urlDebounce: debounce(this.updateUrl, 500),
      documents: [] as HNEntry[],
      baseUrl: "https://server01.seekstorm.com",
      indexId: 0,
      apiKey: "pub_QG065hX9Bj3GI3FXsUFPKWc/sTvf/JAj",
      availableFacets: [] as {
        field: string;
        type: string;
        values: {
          value: string | { from: number; to: number };
          count: number;
        }[];
      }[],
      selectedFacets: {} as { [key: string]: string[] },
      loading: false,
      forceExactQuery: false,
      lastTriggerQuery: false,
      queriedAllDocuments: false,
      rangeFilter: { from: 2007, to: new Date().getUTCFullYear() }
    };
  },

  watch: {
    query(val: string, oldVal: string) {
      if (this.inStartup) return;

      if (val.toLowerCase() != this.actualQuery.toLowerCase()) {
        this.selectedFacets = {};
        if (!oldVal && this.sortBy == "newest") this.sortBy = "";
        if (oldVal && !val && this.sortBy == "") this.sortBy = "newest";
        this.forceExactQuery = false;
        this.lastTriggerQuery = true;
        this.facetFilterQuery = {
          domain: "",
          hashtags: ""
        };
        this.queryDebounce();
      } else {
        this.urlDebounce();
      }
    },
    selectedFacets: {
      deep: true,
      handler() {
        this.lastTriggerQuery = false;
        this.queryDocuments();
      }
    },
    facetFilterQuery: {
      deep: true,
      handler() {
        this.lastTriggerQuery = false;
        this.queryDocuments();
      }
    },
    rangeFilter() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    sortBy() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    searchIn() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    selectedDocument(nVal) {
      if (nVal == null) {
        this.selectedDocumentElement = null;
      }
    },
    selectedDocumentElement() {
      this.updatePointer();
    }
  },

  created() {
    this.updateFromURL();
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document as any).changeUrl = this.changeUrl;
    document.addEventListener("scroll", this.checkScroll);
    window.addEventListener("popstate", this.updateFromURL);

    this.queryDocuments();
  },

  unmounted() {
    document.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("popstate", this.updateFromURL);
  },

  methods: {
    updateFromURL() {
      this.inStartup = true;

      const params = new URLSearchParams(window.location.search);
      console.log(...params.entries());

      this.query = params.get("q") ?? "";
      this.sortBy = params.get("sort") ?? (this.query ? "" : "newest");

      this.searchIn =
        params.get("in") === "all"
          ? []
          : params.get("in")?.split(",") ?? ["Stories"];
      this.forceExactQuery = params.get("fq") !== null || false;
      if (params.has("filter"))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectedFacets = JSON.parse(params.get("filter")!);
      else this.selectedFacets = {};

      this.inStartup = false;
    },

    selectDoc(event: Event, doc: HNEntry) {
      if (window.innerWidth <= 750) {
        event.preventDefault();
        event.stopPropagation();
        this.selectedMobileDocument = doc;
        return false;
      }
    },

    showFilters() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById("sidebarWrapper")!.style.display = "block";
    },

    checkScroll(event: Event | null = null) {
      if (event && event.target != document) return;

      this.selectedDocument = null;
      this.updatePointer();

      if (document.scrollingElement) {
        const bottomReached =
          document.scrollingElement.scrollTop +
            document.scrollingElement.clientHeight >=
          document.scrollingElement.scrollHeight - 100;
        if (bottomReached && !this.queriedAllDocuments) {
          this.queryDocuments(false);
        }
      }
    },

    updatePointer() {
      const elem = document.getElementById("pointer");
      if (elem) {
        if (this.selectedDocumentElement) {
          elem.style.display = "block";
          const elemRect = this.selectedDocumentElement.getBoundingClientRect();
          const elemTop =
            elemRect.top - document.body.getBoundingClientRect().top + 10;
          elem.style.top = elemTop + elemRect.height / 2 + "px";
        } else {
          elem.style.display = "none";
        }
      }
    },

    addHashtag(tag: string) {
      if (this.selectedFacets.hashtags) {
        if (!this.selectedFacets.hashtags.includes(tag))
          this.selectedFacets.hashtags.push(tag);
      } else {
        this.selectedFacets.hashtags = [tag];
      }
    },

    updateUrl() {
      const params = {} as {
        q: string;
        sort: string;
        in: string;
        fq: string;
        filter: string;
      };

      if (this.query) params.q = this.query;
      if (this.sortBy && !(!this.query && this.sortBy == "newest"))
        params.sort = this.sortBy;
      if (this.searchIn.length != 1 || this.searchIn[0] != "Stories")
        params.in = this.searchIn.length ? this.searchIn.join(",") : "all";
      if (this.forceExactQuery) params.fq = "1";
      const filter = JSON.stringify(this.selectedFacets);
      if (
        filter.length > 4 &&
        Object.keys(this.selectedFacets).some(
          k => this.selectedFacets[k]?.length
        )
      )
        params.filter = filter;

      const urlQuery = new URLSearchParams(params).toString();

      const newSearchPath = urlQuery ? `?${urlQuery}` : "/";

      if (urlQuery && window.location.search != newSearchPath)
        window.history.pushState("", "", newSearchPath);

      document.title = this.query
        ? `${this.query} | DeepHN`
        : "DeepHN | powered by SeekStorm";
    },

    queryDocuments(clearDocs = true) {
      if (this.inStartup) return;

      this.urlDebounce();

      if (this.loading) return;

      this.loading = true;

      if (clearDocs) {
        this.queriedAllDocuments = false;
      }

      if (this.queriedAllDocuments) return;
      const filterFacets: {
        field: string;
        value:
          | (string | number | null)[]
          | { from?: number; to?: number; type?: string };
      }[] = [];

      for (const key of Object.keys(this.selectedFacets)) {
        if (["score", "descendants"].includes(key)) {
          if (this.selectedFacets[key])
            filterFacets.push({
              field: key,
              value: {
                type: "InclusiveInclusive",
                ...this.selectedFacets[key]
              }
            });
        } else {
          if (
            this.selectedFacets[key] &&
            this.selectedFacets[key]?.length !== 0
          )
            filterFacets.push({ field: key, value: this.selectedFacets[key] });
        }
      }

      const facetvaluesfilter = [
        { field: "domain", prefix: this.facetFilterQuery.domain },
        { field: "hashtags", prefix: this.facetFilterQuery.hashtags }
      ];

      const timeRange = [];
      const thisYear = new Date().getUTCFullYear();
      for (let i = 2007; i <= thisYear; i++) {
        timeRange.push(Math.fround(new Date(i.toString()).getTime() / 1000));
      }

      let searchInFields;
      if (this.searchIn.length && this.searchIn.length != 3) {
        searchInFields = [];
        if (this.searchIn.includes("Stories"))
          searchInFields.push("title", "text", "url");
        if (this.searchIn.includes("Comments")) searchInFields.push("comments");
        if (this.searchIn.includes("Web"))
          searchInFields.push("titleweb", "textweb");
      }

      let sort: { field: string }[] | { field: string; order: string } = [
        { field: "_rank" },
        { field: "score" }
      ];

      if (this.sortBy) {
        if (this.sortBy == "newest")
          sort = {
            field: "time",
            order: "desc"
          };
        else if (this.sortBy == "oldest")
          sort = {
            field: "time",
            order: "asc"
          };
        else
          sort = {
            field: this.sortBy,
            order: "desc"
          };
      }

      fetch(`${this.baseUrl}/indices/${this.indexId}/documents/query`, {
        headers: {
          apiKey: this.apiKey
        },
        method: "POST",
        body: JSON.stringify({
          query: this.query,
          queryType: this.forceExactQuery ? "search" : "instant",
          completion: true,
          length: QUERY_LENGTH,
          facetvalueslength: 7,
          offset: clearDocs ? 0 : this.documents.length,
          filter: [
            {
              field: "time",
              value: {
                type: "InclusiveExclusive",
                from: Math.round(
                  new Date(this.rangeFilter.from.toString()).getTime() / 1000
                ),
                to: Math.round(
                  new Date((this.rangeFilter.to + 1).toString()).getTime() /
                    1000
                )
              }
            },
            ...filterFacets
          ],
          facetvaluesfilter,
          ranges: [
            {
              field: "time",
              sections: timeRange
            },
            {
              field: "descendants",
              sectionType: "SumAboveInclusive",
              sections: [10, 100, 1000]
            },
            {
              field: "score",
              sectionType: "SumAboveInclusive",
              sections: [10, 100, 1000]
            }
          ],
          sort,
          fields: searchInFields
        })
      })
        .then(async resp => {
          const data = await resp.json();

          if (data.results.length < QUERY_LENGTH)
            this.queriedAllDocuments = true;

          if (clearDocs) this.documents = data.results;
          else this.documents.push(...data.results);
          this.suggestions = data.suggestions;
          this.count = data.count;
          this.actualQuery = data.query;
          this.availableFacets = data.facets;
          this.loading = false;

          setTimeout(() => {
            this.checkScroll();
          }, 100);
        })
        .catch(() => (this.loading = false));
    },

    addUrlFilter(url: string) {
      if (window.innerWidth <= 750) return;

      if (this.selectedFacets.domain) {
        if (!this.selectedFacets.domain.includes(url))
          this.selectedFacets.domain.push(url);
      } else {
        this.selectedFacets.domain = [url];
      }
    },

    // TODO remove
    changeUrl(newUrl: string, indexId: number, key: string) {
      this.baseUrl = newUrl;
      this.indexId = indexId;
      this.apiKey = key;
    }
  }
});
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
  font-family: "Montserrat", sans-serif;
  color: $grey;

  display: flex;
  padding: 0 30px;

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  a {
    color: $secondary;
    text-decoration: none;
  }

  #mainSearch {
    width: 100%;
    flex: 1;

    @media (max-width: 750px) {
      &.mobileHide {
        display: none;
      }
    }

    .mobileTitle {
      @media (min-width: 1200px) {
        display: none;
      }

      font-size: 10pt;
      margin-top: 20px;

      h1 {
        color: $primary;
        font-weight: normal;
        margin: 0;
        margin-left: -2px;
      }

      p {
        margin-top: 2px;
        margin-bottom: 5px;
        font-size: 10pt;
      }
    }

    #filterBtn {
      margin: 15px 0;
      display: flex;
      align-items: center;
      background-color: $primary;
      color: white;
      cursor: pointer;
      padding: 3px 7px;
      font-size: 10pt;
      width: fit-content;

      img {
        margin-right: 3px;
      }

      @media (min-width: 1200px) {
        display: none;
      }
    }

    #searchbar {
      width: 80%;
      margin: 35px auto 0;

      @media (max-width: 500px) {
        margin-top: 20px;
        width: 100%;
      }
    }

    #searchbar-details {
      display: flex;
      width: 80%;
      margin: 5px auto 80px;
      justify-content: space-between;

      @media (max-width: 500px) {
        width: 100%;
      }

      div:nth-child(1) {
        flex: 1;
      }

      div:nth-child(2) {
        flex: 2;
        text-align: right;
      }
    }
  }

  #sortByMobile {
    display: block;
    font-size: 9pt;
    margin-top: 10px;

    @media (min-width: 500px) {
      display: none;
    }
  }

  .resultLegend {
    margin-left: -4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 5px;

    @media (max-width: 500px) {
      margin-top: 5px;
    }

    #sortBy {
      font-size: 9pt;
      margin-left: 3px;

      @media (max-width: 500px) {
        display: none;
      }
    }

    div {
      cursor: pointer;
      // width: 40px;
      font-size: 9pt;
      font-weight: 600;
      padding: 2px 8px;
      text-overflow: clip;
      // text-align: right;

      &.active {
        background-color: $primary;
        color: white;
        transform: skew(-15deg);
        font-weight: 500;

        span {
          display: block;
          transform: skew(15deg);
        }
      }
    }
  }

  .loadingBlock {
    width: 100%;
    height: 20px;
    opacity: 0.8;

    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 8%,
      #dfdfdf 38%,
      rgba(255, 255, 255, 0) 54%
    );
    background-size: 1000px 640px;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -20vw 0;
    }
    100% {
      background-position: 500vw 0;
    }
  }
}
</style>
