
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      showSuggestions: false,
      suggestionSelection: -1,
      lastActionDel: false
    };
  },

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: String,
      required: true
    },
    correction: {
      type: String,
      required: true
    },
    suggestions: {
      type: Array as () => string[],
      default: []
    }
  },

  watch: {
    correction(val) {
      if (this.isMobile) return;

      if (
        val.toLowerCase().startsWith(this.modelValue.toLowerCase()) &&
        !this.lastActionDel
      ) {
        const input = this.modelValue + val.substring(this.modelValue.length);
        // this.$emit("update:modelValue", input);
        (this.$refs.input as HTMLInputElement).value = input;
        (this.$refs.input as HTMLInputElement).setSelectionRange(
          this.modelValue.length,
          val.length
        );
      }
    },

    modelValue() {
      if (this.isMobile) return;

      this.checkModelValue();
    }
  },

  computed: {
    suggestionsClean(): string[] {
      return this.suggestions.filter(sugg => sugg != this.modelValue);
    },

    isMobile(): boolean {
      return window.innerWidth <= 750;
    }
  },

  mounted() {
    this.checkModelValue();
  },

  methods: {
    selectSuggestion(sugg: string) {
      (this.$refs.input as HTMLInputElement).value = sugg;
      (this.$refs.input as HTMLInputElement).blur();
      this.$emit("update:modelValue", sugg);
    },

    checkModelValue() {
      this.suggestionSelection = -1;

      if ((this.$refs.input as HTMLInputElement).value != this.modelValue) {
        (this.$refs.input as HTMLInputElement).value = this.modelValue;
        // alert("T1");
      }

      if (
        this.correction
          .toLowerCase()
          .startsWith(this.modelValue.toLowerCase()) &&
        !this.lastActionDel
      ) {
        const input =
          this.modelValue + this.correction.substring(this.modelValue.length);
        (this.$refs.input as HTMLInputElement).value = input;
        (this.$refs.input as HTMLInputElement).setSelectionRange(
          this.modelValue.length,
          this.correction.length
        );
        // alert("T2");
      }
    },

    handleKey(event: KeyboardEvent) {
      if (event.key == "Backspace") {
        this.lastActionDel = true;
      } else {
        this.lastActionDel = false;
      }

      if (event.key == "Escape") {
        (this.$refs.input as HTMLInputElement).blur();
      }

      if (event.key == "Enter") {
        if (
          this.suggestionSelection != -1 &&
          this.suggestions[this.suggestionSelection]?.toLowerCase() !=
            (this.$refs.input as HTMLInputElement).value.toLowerCase()
        ) {
          const sugg = this.suggestions[
            this.suggestionSelection
          ]?.toLowerCase();
          const input = sugg.startsWith(this.modelValue.toLowerCase())
            ? this.modelValue + sugg.substring(this.modelValue.length)
            : sugg;
          this.$emit("update:modelValue", input);
        } else {
          (this.$refs.input as HTMLInputElement).blur();
        }
      }

      if (event.key == "ArrowDown") {
        if (this.suggestionsClean.length > this.suggestionSelection + 1) {
          this.suggestionSelection += 1;
        } else {
          this.suggestionSelection = -1;
        }
      } else if (event.key == "ArrowUp") {
        if (this.suggestionSelection - 1 >= -1) {
          this.suggestionSelection -= 1;
        }
      }
    }
  }
});
