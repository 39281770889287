
import Preview from "@/components/Preview.vue";
import Result from "@/components/Result.vue";
import Searchbar from "@/components/Searchbar.vue";
import { defineComponent } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import Histogram from "@/components/Histogram.vue";

import { debounce } from "@/utils/utils";
import { HNEntry } from "@/utils/HNEntry";

const QUERY_LENGTH = 20;

export default defineComponent({
  name: "App",
  components: { Sidebar, Searchbar, Histogram, Result, Preview },

  data() {
    return {
      query: "",
      actualQuery: "",
      sortBy: "newest",
      searchIn: ["Stories"] as string[],
      facetFilterQuery: {
        domain: "",
        hashtags: ""
      },
      count: "-" as number | string,
      inStartup: true,
      suggestions: [] as string[],
      selectedMobileDocument: null as null | HNEntry,
      selectedDocument: null as null | HNEntry,
      selectedDocumentElement: null as null | HTMLElement,
      queryDebounce: debounce(this.queryDocuments, 150),
      urlDebounce: debounce(this.updateUrl, 500),
      documents: [] as HNEntry[],
      baseUrl: "https://server01.seekstorm.com",
      indexId: 0,
      apiKey: "pub_QG065hX9Bj3GI3FXsUFPKWc/sTvf/JAj",
      availableFacets: [] as {
        field: string;
        type: string;
        values: {
          value: string | { from: number; to: number };
          count: number;
        }[];
      }[],
      selectedFacets: {} as { [key: string]: string[] },
      loading: false,
      forceExactQuery: false,
      lastTriggerQuery: false,
      queriedAllDocuments: false,
      rangeFilter: { from: 2007, to: new Date().getUTCFullYear() }
    };
  },

  watch: {
    query(val: string, oldVal: string) {
      if (this.inStartup) return;

      if (val.toLowerCase() != this.actualQuery.toLowerCase()) {
        this.selectedFacets = {};
        if (!oldVal && this.sortBy == "newest") this.sortBy = "";
        if (oldVal && !val && this.sortBy == "") this.sortBy = "newest";
        this.forceExactQuery = false;
        this.lastTriggerQuery = true;
        this.facetFilterQuery = {
          domain: "",
          hashtags: ""
        };
        this.queryDebounce();
      } else {
        this.urlDebounce();
      }
    },
    selectedFacets: {
      deep: true,
      handler() {
        this.lastTriggerQuery = false;
        this.queryDocuments();
      }
    },
    facetFilterQuery: {
      deep: true,
      handler() {
        this.lastTriggerQuery = false;
        this.queryDocuments();
      }
    },
    rangeFilter() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    sortBy() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    searchIn() {
      this.lastTriggerQuery = false;
      this.queryDebounce();
    },
    selectedDocument(nVal) {
      if (nVal == null) {
        this.selectedDocumentElement = null;
      }
    },
    selectedDocumentElement() {
      this.updatePointer();
    }
  },

  created() {
    this.updateFromURL();
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document as any).changeUrl = this.changeUrl;
    document.addEventListener("scroll", this.checkScroll);
    window.addEventListener("popstate", this.updateFromURL);

    this.queryDocuments();
  },

  unmounted() {
    document.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("popstate", this.updateFromURL);
  },

  methods: {
    updateFromURL() {
      this.inStartup = true;

      const params = new URLSearchParams(window.location.search);
      console.log(...params.entries());

      this.query = params.get("q") ?? "";
      this.sortBy = params.get("sort") ?? (this.query ? "" : "newest");

      this.searchIn =
        params.get("in") === "all"
          ? []
          : params.get("in")?.split(",") ?? ["Stories"];
      this.forceExactQuery = params.get("fq") !== null || false;
      if (params.has("filter"))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectedFacets = JSON.parse(params.get("filter")!);
      else this.selectedFacets = {};

      this.inStartup = false;
    },

    selectDoc(event: Event, doc: HNEntry) {
      if (window.innerWidth <= 750) {
        event.preventDefault();
        event.stopPropagation();
        this.selectedMobileDocument = doc;
        return false;
      }
    },

    showFilters() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById("sidebarWrapper")!.style.display = "block";
    },

    checkScroll(event: Event | null = null) {
      if (event && event.target != document) return;

      this.selectedDocument = null;
      this.updatePointer();

      if (document.scrollingElement) {
        const bottomReached =
          document.scrollingElement.scrollTop +
            document.scrollingElement.clientHeight >=
          document.scrollingElement.scrollHeight - 100;
        if (bottomReached && !this.queriedAllDocuments) {
          this.queryDocuments(false);
        }
      }
    },

    updatePointer() {
      const elem = document.getElementById("pointer");
      if (elem) {
        if (this.selectedDocumentElement) {
          elem.style.display = "block";
          const elemRect = this.selectedDocumentElement.getBoundingClientRect();
          const elemTop =
            elemRect.top - document.body.getBoundingClientRect().top + 10;
          elem.style.top = elemTop + elemRect.height / 2 + "px";
        } else {
          elem.style.display = "none";
        }
      }
    },

    addHashtag(tag: string) {
      if (this.selectedFacets.hashtags) {
        if (!this.selectedFacets.hashtags.includes(tag))
          this.selectedFacets.hashtags.push(tag);
      } else {
        this.selectedFacets.hashtags = [tag];
      }
    },

    updateUrl() {
      const params = {} as {
        q: string;
        sort: string;
        in: string;
        fq: string;
        filter: string;
      };

      if (this.query) params.q = this.query;
      if (this.sortBy && !(!this.query && this.sortBy == "newest"))
        params.sort = this.sortBy;
      if (this.searchIn.length != 1 || this.searchIn[0] != "Stories")
        params.in = this.searchIn.length ? this.searchIn.join(",") : "all";
      if (this.forceExactQuery) params.fq = "1";
      const filter = JSON.stringify(this.selectedFacets);
      if (
        filter.length > 4 &&
        Object.keys(this.selectedFacets).some(
          k => this.selectedFacets[k]?.length
        )
      )
        params.filter = filter;

      const urlQuery = new URLSearchParams(params).toString();

      const newSearchPath = urlQuery ? `?${urlQuery}` : "/";

      if (urlQuery && window.location.search != newSearchPath)
        window.history.pushState("", "", newSearchPath);

      document.title = this.query
        ? `${this.query} | DeepHN`
        : "DeepHN | powered by SeekStorm";
    },

    queryDocuments(clearDocs = true) {
      if (this.inStartup) return;

      this.urlDebounce();

      if (this.loading) return;

      this.loading = true;

      if (clearDocs) {
        this.queriedAllDocuments = false;
      }

      if (this.queriedAllDocuments) return;
      const filterFacets: {
        field: string;
        value:
          | (string | number | null)[]
          | { from?: number; to?: number; type?: string };
      }[] = [];

      for (const key of Object.keys(this.selectedFacets)) {
        if (["score", "descendants"].includes(key)) {
          if (this.selectedFacets[key])
            filterFacets.push({
              field: key,
              value: {
                type: "InclusiveInclusive",
                ...this.selectedFacets[key]
              }
            });
        } else {
          if (
            this.selectedFacets[key] &&
            this.selectedFacets[key]?.length !== 0
          )
            filterFacets.push({ field: key, value: this.selectedFacets[key] });
        }
      }

      const facetvaluesfilter = [
        { field: "domain", prefix: this.facetFilterQuery.domain },
        { field: "hashtags", prefix: this.facetFilterQuery.hashtags }
      ];

      const timeRange = [];
      const thisYear = new Date().getUTCFullYear();
      for (let i = 2007; i <= thisYear; i++) {
        timeRange.push(Math.fround(new Date(i.toString()).getTime() / 1000));
      }

      let searchInFields;
      if (this.searchIn.length && this.searchIn.length != 3) {
        searchInFields = [];
        if (this.searchIn.includes("Stories"))
          searchInFields.push("title", "text", "url");
        if (this.searchIn.includes("Comments")) searchInFields.push("comments");
        if (this.searchIn.includes("Web"))
          searchInFields.push("titleweb", "textweb");
      }

      let sort: { field: string }[] | { field: string; order: string } = [
        { field: "_rank" },
        { field: "score" }
      ];

      if (this.sortBy) {
        if (this.sortBy == "newest")
          sort = {
            field: "time",
            order: "desc"
          };
        else if (this.sortBy == "oldest")
          sort = {
            field: "time",
            order: "asc"
          };
        else
          sort = {
            field: this.sortBy,
            order: "desc"
          };
      }

      fetch(`${this.baseUrl}/indices/${this.indexId}/documents/query`, {
        headers: {
          apiKey: this.apiKey
        },
        method: "POST",
        body: JSON.stringify({
          query: this.query,
          queryType: this.forceExactQuery ? "search" : "instant",
          completion: true,
          length: QUERY_LENGTH,
          facetvalueslength: 7,
          offset: clearDocs ? 0 : this.documents.length,
          filter: [
            {
              field: "time",
              value: {
                type: "InclusiveExclusive",
                from: Math.round(
                  new Date(this.rangeFilter.from.toString()).getTime() / 1000
                ),
                to: Math.round(
                  new Date((this.rangeFilter.to + 1).toString()).getTime() /
                    1000
                )
              }
            },
            ...filterFacets
          ],
          facetvaluesfilter,
          ranges: [
            {
              field: "time",
              sections: timeRange
            },
            {
              field: "descendants",
              sectionType: "SumAboveInclusive",
              sections: [10, 100, 1000]
            },
            {
              field: "score",
              sectionType: "SumAboveInclusive",
              sections: [10, 100, 1000]
            }
          ],
          sort,
          fields: searchInFields
        })
      })
        .then(async resp => {
          const data = await resp.json();

          if (data.results.length < QUERY_LENGTH)
            this.queriedAllDocuments = true;

          if (clearDocs) this.documents = data.results;
          else this.documents.push(...data.results);
          this.suggestions = data.suggestions;
          this.count = data.count;
          this.actualQuery = data.query;
          this.availableFacets = data.facets;
          this.loading = false;

          setTimeout(() => {
            this.checkScroll();
          }, 100);
        })
        .catch(() => (this.loading = false));
    },

    addUrlFilter(url: string) {
      if (window.innerWidth <= 750) return;

      if (this.selectedFacets.domain) {
        if (!this.selectedFacets.domain.includes(url))
          this.selectedFacets.domain.push(url);
      } else {
        this.selectedFacets.domain = [url];
      }
    },

    // TODO remove
    changeUrl(newUrl: string, indexId: number, key: string) {
      this.baseUrl = newUrl;
      this.indexId = indexId;
      this.apiKey = key;
    }
  }
});
