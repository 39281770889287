
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      lowerEdit: false,
      updater: null as null | ((val: number) => void)
    };
  },

  mounted() {
    document.addEventListener("mouseup", this.endDrag, { capture: true });
    document.addEventListener("touchend", this.endDrag, { capture: true });
    document.addEventListener("mousemove", this.updateMouse, { capture: true });
    document.addEventListener("touchmove", this.updateTouch, { capture: true });
  },

  unmounted() {
    document.removeEventListener("mouseup", this.endDrag, { capture: true });
    document.removeEventListener("touchend", this.endDrag, { capture: true });
    document.removeEventListener("mousemove", this.updateMouse, {
      capture: true
    });
    document.removeEventListener("touchmove", this.updateTouch, {
      capture: true
    });
  },

  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    lower: {
      type: Number,
      required: true
    },
    higher: {
      type: Number,
      required: true
    },
    round: {
      type: Boolean,
      default: true
    }
  },

  emits: ["update:lower", "update:higher"],

  computed: {
    lowerRel(): number {
      return (this.lower - this.min) / (this.max - this.min);
    },
    higherRel(): number {
      return (this.higher - this.min) / (this.max - this.min);
    }
  },

  methods: {
    startDragLower() {
      this.lowerEdit = true;
      this.updater = val => this.$emit("update:lower", val);
    },

    startDragHigher() {
      this.lowerEdit = false;
      this.updater = val => this.$emit("update:higher", val);
    },

    updateMouse(event: MouseEvent) {
      this.updateHandle(event.clientX);
    },

    updateTouch(event: TouchEvent) {
      const touch = event.touches.item(0);
      if (touch != null && this.updater != null) {
        event.preventDefault();
        this.updateHandle(touch.clientX);
      }
    },

    updateHandle(xPos: number) {
      if (this.updater != null) {
        const box = (this.$refs.range as HTMLElement).getBoundingClientRect();
        let val =
          ((xPos - box.x) / box.width) * (this.max - this.min) + this.min;

        if (
          val < this.min ||
          val > this.max ||
          (this.lowerEdit && val > this.higher) ||
          (!this.lowerEdit && val < this.lower)
        )
          return;

        if (this.round) val = Math.round(val);

        this.updater(val);
      }
    },

    endDrag() {
      this.updater = null;
    }
  }
});
