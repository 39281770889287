
import RangeSlider from "./RangeSlider.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { RangeSlider },
  data() {
    return {
      thisYear: new Date().getUTCFullYear(),
      hoverText: null as null | string
    };
  },

  props: {
    values: {
      type: Array as () => number[],
      required: true
    },
    rangeFilter: {
      type: Object as () => { from: number; to: number },
      required: true
    }
  },

  emits: ["update:rangeFilter"],

  computed: {
    max(): number {
      return this.values.reduce((v1, v2) => Math.max(v1, v2), 1);
    }
  }
});
