<template>
  <div id="sidebarWrapper">
    <div id="blurrer" @click="closeFilter"></div>
    <section id="sidebar">
      <a href="/" style="color: unset">
        <h1>DeepHN</h1>
      </a>
      <p>
        Full-text search 30M
        <a
          href="https://news.ycombinator.com/"
          target="_blank"
          rel="noopener noreferrer"
          >Hacker News</a
        ><a
          href="https://seekstorm.com/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
          >*</a
        >
        <br />
        posts AND linked web pages
      </p>
      <div class="divider"></div>
      <a
        href="http://seekstorm.com"
        target="_blank"
        rel="noopener noreferrer"
        style="color: unset"
      >
        <span>Powered by</span>
        <h1 class="cuddle">SeekStorm</h1>
        <span>Search-as-a-service</span>
      </a>

      <facet-filter
        v-if="hasQuery"
        name="Search in"
        :modelValue="searchIn"
        :values="[
          { value: 'Stories' },
          { value: 'Comments' },
          { value: 'Web' }
        ]"
        @update:modelValue="val => $emit('update:searchIn', val)"
      ></facet-filter>

      <facet-filter
        v-for="facet in availableFacets.filter(f => !!facetNames[f.field])"
        :key="facet.field"
        :name="facetNames[facet.field]"
        :range="facet.type !== 'stringFacet'"
        :modelValue="facets[facet.field]"
        :values="facet.values"
        :query="facetFilterQuery[facet.field]"
        @update:query="
          val =>
            $emit('update:facetFilterQuery', {
              ...facetFilterQuery,
              [facet.field]: val
            })
        "
        @update:modelValue="
          val => $emit('update:facets', { ...facets, [facet.field]: val })
        "
      ></facet-filter>

      <div class="links">
        <a
          href="http://seekstorm.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >Privacy Policy</a
        >
        <a
          href="https://seekstorm.com/about-deephn"
          target="_blank"
          rel="noopener noreferrer"
          >About</a
        >
        <a
          href="http://seekstorm.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          >Contact</a
        >
      </div>

      <div id="applyFilter" @click="closeFilter">Close</div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FacetFilter from "./FacetFilter.vue";

export default defineComponent({
  components: { FacetFilter },
  data() {
    return {
      facetNames: {
        hashtags: "Hashtags",
        score: "Points",
        descendants: "Comments",
        domain: "Domain"
      }
    };
  },

  emits: ["update:facets", "update:searchIn", "update:facetFilterQuery"],

  props: {
    hasQuery: {
      type: Boolean,
      required: true
    },
    availableFacets: {
      type: Array as () => { field: string; type: string; values: [] }[],
      required: true
    },
    facets: {
      type: Object,
      required: true
    },
    searchIn: {
      type: Array as () => string[],
      required: true
    },
    facetFilterQuery: {
      type: Object as () => { [key: string]: string },
      required: true
    }
  },

  methods: {
    closeFilter() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById("sidebarWrapper")!.style.display = "";
    }
  }
});
</script>

<style lang="scss" scoped>
#sidebarWrapper,
#sidebar {
  max-width: 100vw;
  width: 250px;
  margin-right: 25px;

  @media (max-width: 1200px) {
    width: 300px;
    margin-right: 0px;
    padding-right: 35px;
    padding-left: 35px;
  }
}

#blurrer {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;

  @media (min-width: 1200px) {
    display: none;
  }
}

#sidebarWrapper {
  @media (max-width: 1200px) {
    margin: 0;
    padding: 0;
    left: 0;
    display: none;
    z-index: 5;
    position: absolute;
  }
}

#sidebar {
  background-color: white;
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  top: 0;
  padding-top: 30px;
  padding-bottom: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }

  #applyFilter {
    background-color: $primary;
    color: white;
    cursor: pointer;
    padding: 9px 7px;
    text-align: center;
    font-size: 10pt;
    position: fixed;
    bottom: 0;
    left: 0;

    max-width: 100vw;
    width: 300px;

    box-sizing: border-box;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  h1 {
    color: $primary;
    font-weight: normal;
    margin: 0;

    &.cuddle {
      margin: -3px 0 -5px;
    }
  }

  p {
    margin-top: 2px;
    font-size: 10pt;

    a {
      color: $grey;
      // text-decoration: underline;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $primary;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .actionBox {
    h4 {
      margin-bottom: 5px;
      color: $darkgrey;
      font-weight: 500;
    }
  }

  span {
    font-size: 10pt;
  }

  .links {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    font-size: 10pt;

    a {
      margin-right: 12px;
    }

    // a:last-child {
    //   margin-left: 15px;
    // }
  }
}
</style>
